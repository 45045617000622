:root {
  --standard-space: calc(1em / 3);
}

.spinner-message > p {
  display: flex;
  align-items: center;
}

.spinner-message > p > .Polaris-Spinner {
  margin-right: var(--standard-space);
}

.icon-message > div {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.icon-message > div > span:not(.Polaris-Icon) {
  flex: 1;
}

.icon-message > div > .Polaris-Icon {
  margin-right: var(--standard-space);
}

.icon-message > .Polaris-List {
  /*
  Line the list up with the message by matching the .Polaris-Icon
  width (2rem), offset by the standard space.
  */
  margin-left: calc(2rem + var(--standard-space));
}

.myshopify-placeholder {
  padding: 6px 12px;
  text-align: center;
  background-color: #eee;
  border-radius: 4px;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
